var g = Object.defineProperty;
var b = (t, e, n) => e in t ? g(t, e, { enumerable: !0, configurable: !0, writable: !0, value: n }) : t[e] = n;
var l = (t, e, n) => (b(t, typeof e != "symbol" ? e + "" : e, n), n);
const f = typeof window < "u";
function h() {
  var t = navigator.userAgent.toLowerCase() || navigator.vendor.toLowerCase() || window.opera;
  return /windows phone/i.test(t) ? "windows" : /android/i.test(t) ? "android" : /ipad|iphone|ipod/.test(t) && !window.MSStream ? "ios" : "desktop";
}
const w = h(), y = !!navigator.userAgent.toLowerCase().match(/(ipad)|(iphone)|(ipod)|(android)|(webos)/i), x = navigator.userAgent.toLowerCase().includes("canvas");
class B {
  constructor(e) {
    l(this, "button");
    l(this, "display", "onLoad");
    l(this, "delay");
    l(this, "banner");
    if (!f)
      return;
    e = Object.assign({}, {
      fontFamily: '"Source Sans Pro", "Arial", sans-serif',
      // Font family for banner texts, defaults to system safe fonts
      textColor: "#222",
      // Banner texts color (any color property value),
      textHeading: "Get a discount on your first purchase",
      // Heading Text
      textDescription: "Use coupon <strong onclick=navigator.clipboard.writeText(this.textContent)>10OFF</strong>",
      // Description text
      bannerColor: "white",
      // Banner BG color
      link: "#",
      // Link for button
      position: "top",
      // Position of the banner, default 'top'. 'top' | 'bottom'
      animation: "fadeIn",
      // Banner animation, default 'fadeIn'. 'fadeIn' | 'scaleUp' | 'slideBottom' | 'slideTop' | 'slideLeft' | 'slideRight' | null,
      display: "onLoad",
      // Display options, default 'onLoad'. 'onLoad' | 'onScrollDown' | 'onScrollUp'
      radius: "0",
      // Banner radius with units
      delay: 0,
      // defines how much time to wait until the element shows up in ms
      shadow: !0,
      // If true applies soft shadow, true | false
      useSession: !1,
      zindex: 9999999
    }, e);
    const a = `
    
    .ml-promotionsBanner {
      display:none;
      position: fixed;
      ${e.position === "bottom" ? "bottom: 0" : "top: 0"};
      left: 0;
      width: 100%;
      z-index: ${e.zindex};
      background-color: ${e.bannerColor};
      box-shadow: ${e.shadow ? "0 0 4px 1px #00000014" : "none"} ;
      transition: all 0.3ms ease-in-out;
      font-family: ${e.fontFamily};
      animation: ${e.animation + " 0.5s both"};
      font-size: 1rem;
      border-radius: ${e.radius};
    }

    .ml-promotionsBanner__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em 1.5em;
      gap: 15px
    }
    
    .ml-promotionsBanner__description {margin: 0}

    .ml-promotionsBanner__title { margin: 0 0 5px 0}
  
    .ml-promotionsBanner__content {
      text-align: center;
      color: ${e.textColor}
    }

    .ml-promotionsBanner__closebutton {
      position: absolute;
      top: 0;
      right: 5px;
      font-size: 30px;
      font-weight: revert;
      cursor: pointer;
      line-height: 30px;
      color: ${e.textColor}
    }
    
    .ml-promotionsBanner-toggle--visible{
      display: block;
    }
      
    @keyframes fadeIn {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
       }
  }

  @keyframes scaleUp {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes slideBottom {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideTop {
    0% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0);
    }
  }
      /* CHECK MEDIA CLASSES */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .promotionsBanner-toggle {display: none !important}
      }

      @supports (-ms-ime-align:auto), (-ms-accelerator:true) {
        .promotionsBanner-toggle {display: none !important}
      }
    `;
    function c(s) {
      const o = document.createElement("div");
      o.className = "ml-promotionsBanner";
      const d = document.createElement("div");
      d.className = "ml-promotionsBanner__wrapper";
      const r = document.createElement("a");
      r.className = "ml-promotionsBanner__content", r.href = s.link;
      const p = document.createElement("h4");
      p.className = "ml-promotionsBanner__title", p.innerHTML = s.textHeading, r.appendChild(p);
      const u = document.createElement("p");
      u.className = "ml-promotionsBanner__description", u.innerHTML = s.textDescription, r.appendChild(u);
      const i = document.createElement("span");
      return i.id = "ml-promotionsBanner__closebutton", i.className = "ml-promotionsBanner__closebutton", i.textContent = "×", i.addEventListener("click", () => {
        o.classList.remove("ml-promotionsBanner-toggle--visible"), s.useSession && window.sessionStorage.setItem("bannerClosed", "true");
      }), d.appendChild(r), d.appendChild(i), o.appendChild(d), document.body.appendChild(o), o;
    }
    let m = c(e);
    this.addStyle(a), this.display = e.display, this.delay = e.delay, this.banner = m;
  }
  // (1) inserts css in page
  addStyle(e) {
    const n = document.createElement("link");
    n.setAttribute("rel", "stylesheet"), n.setAttribute("type", "text/css"), n.setAttribute("href", "data:text/css;charset=UTF-8," + encodeURIComponent(e)), document.head.appendChild(n);
  }
  init() {
    const e = window.sessionStorage.getItem("bannerClosed"), n = this.display, a = this.banner, c = this.delay;
    if (!f || JSON.parse(e))
      return;
    const m = () => {
      let s = 0;
      switch (n) {
        case "onLoad":
          a.classList.add("ml-promotionsBanner-toggle--visible");
          break;
        case "onScrollDown":
          window.addEventListener("scroll", function() {
            let o = window.scrollY || document.documentElement.scrollTop;
            o < s ? a.classList.remove("ml-promotionsBanner-toggle--visible") : a.classList.add("ml-promotionsBanner-toggle--visible"), s = o;
          });
          break;
        case "onScrollUp":
          window.addEventListener("scroll", function() {
            let o = window.scrollY || document.documentElement.scrollTop;
            o > s ? a.classList.remove("ml-promotionsBanner-toggle--visible") : a.classList.add("ml-promotionsBanner-toggle--visible"), s = o;
          });
          break;
        default:
          this.button.classList.add("ml-promotionsBanner-toggle--visible");
          break;
      }
    };
    setTimeout(() => {
      m();
    }, c);
  }
}
const v = {
  os: w,
  isMobile: y,
  isCanvas: x
};
f && function(t) {
  t.PromotionsBanner = B, t.deviceData = v;
}(window);
export {
  B as PromotionsBanner,
  v as deviceData
};
